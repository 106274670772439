.champion-selector-title {
    display: flex;
    background: #fff;
    padding: 0.5em;
    font-size: 1.8rem;
    font-weight: 300;
    z-index: 99;
    border-bottom: solid 2px #00000033;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    white-space: nowrap;
}

.champion-selector-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
}

.champion-selector-title input {
    outline: none;
    border: none;
    background: #eee;
    border-radius: 0 5em 5em 0;
    padding: 0 .5em;
    font-size: 20px;
    width: 90%;
    height: 30px;
}

.champion-selector-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.champion-tile-img {
    width: calc(100%/6);
    aspect-ratio: 1;
    background-size: contain;
    border: none;
    outline: none;
    border-radius: 25%;
    box-shadow: 0 0 5px #00000088;
    margin: calc(100%/60);
    transition: 100ms ease-in-out;
}

.champion-tile-img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.champion-tile-img:not(:hover) {
    opacity: 90%;
}

@media screen and (max-width: 850px) {

    .champion-selector-title {
        flex-flow: column;
    }

    .champion-selector-search {
        margin-top: .5em;
    }

}