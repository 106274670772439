.shopper-back {
    position: fixed;
    top: 0;
    background: #00000066;
    height: 100vh;
    width: 100vw;
}

.shopper-wrapper {
    position: fixed;
    top: 5%;
    left: calc(50% - 37.5%);
    background: white;
    width: 75%;
    min-height: 50%;
    border-radius: 2em;
    padding: 1em;
}

.key-value-pair {
    display: flex;
    justify-content: space-between;
    background: #eee;
    max-width: 20%;
    border-radius: 2em;
    font-size: 1em;
    padding: .2em .5em;
    margin: .5em 0;
}

.shopper-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.shopper-title-img {
    border-radius: 25%;
    box-shadow: 0 0 5px #00000088;
}

.key {

}